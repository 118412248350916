<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            disabled
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="code">Code</vs-th>
        <vs-th sort-key="date">Date</vs-th>
        <vs-th sort-key="so_type">SO Type</vs-th>
        <vs-th sort-key="expense_type">Expense Type</vs-th>
        <vs-th sort-key="so_date">SO Date</vs-th>
        <vs-th sort-key="order_code">Order Code</vs-th>
        <vs-th sort-key="delivery_code">Delivery Code</vs-th>
        <vs-th sort-key="sales_office">Sales Office</vs-th>
        <vs-th sort-key="sales_office_desc">Sales Office Desc</vs-th>
        <vs-th sort-key="sold_to_party">Sold To Party</vs-th>
        <vs-th sort-key="sold_to_party_name">Sold To Party Name</vs-th>
        <vs-th sort-key="ship_to_party">Ship To Party</vs-th>
        <vs-th sort-key="ship_to_party_name">Ship To Party Name</vs-th>
        <vs-th sort-key="dist_channel">Dist Channel</vs-th>
        <vs-th sort-key="sales_code">Sales Code</vs-th>
        <vs-th sort-key="sales_code_desc">Sales Code Desc</vs-th>
        <vs-th sort-key="material_code">Material Code</vs-th>
        <vs-th sort-key="material_desc">Material Desc</vs-th>
        <vs-th sort-key="div_desc">Div Desc</vs-th>
        <vs-th sort-key="price">Price</vs-th>
        <vs-th sort-key="qty">Qty</vs-th>
        <vs-th sort-key="uom">Uom</vs-th>
        <vs-th sort-key="unit">Unit</vs-th>
        <vs-th sort-key="amount">Amount</vs-th>
        <vs-th sort-key="discount">Discount</vs-th>
        <vs-th sort-key="subtotal">Subtotal</vs-th>
        <vs-th sort-key="tax_amount">Tax Amount</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="is_free_item">Is Free Item</vs-th>
        <vs-th sort-key="status">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].code"> {{ data[indextr].code }} </vs-td>
          <vs-td :data="data[indextr].date"> {{ data[indextr].date }} </vs-td>
          <vs-td :data="data[indextr].so_type"> {{ data[indextr].so_type }} </vs-td>
          <vs-td :data="data[indextr].expense_type"> {{ data[indextr].expense_type }} </vs-td>
          <vs-td :data="data[indextr].so_date"> {{ data[indextr].so_date }} </vs-td>
          <vs-td :data="data[indextr].order_code"> {{ data[indextr].order_code }} </vs-td>
          <vs-td :data="data[indextr].delivery_code"> {{ data[indextr].delivery_code }} </vs-td>
          <vs-td :data="data[indextr].sales_office"> {{ data[indextr].sales_office }} </vs-td>
          <vs-td :data="data[indextr].sales_office_desc"> {{ data[indextr].sales_office_desc }} </vs-td>
          <vs-td :data="data[indextr].sold_to_party"> {{ data[indextr].sold_to_party }} </vs-td>
          <vs-td :data="data[indextr].sold_to_party_name"> {{ data[indextr].sold_to_party_name }} </vs-td>
          <vs-td :data="data[indextr].ship_to_party"> {{ data[indextr].ship_to_party }} </vs-td>
          <vs-td :data="data[indextr].ship_to_party_name"> {{ data[indextr].ship_to_party_name }} </vs-td>
          <vs-td :data="data[indextr].dist_channel"> {{ data[indextr].dist_channel }} </vs-td>
          <vs-td :data="data[indextr].sales_code"> {{ data[indextr].sales_code }} </vs-td>
          <vs-td :data="data[indextr].sales_code_desc"> {{ data[indextr].sales_code_desc }} </vs-td>
          <vs-td :data="data[indextr].material_code"> {{ data[indextr].material_code }} </vs-td>
          <vs-td :data="data[indextr].material_desc"> {{ data[indextr].material_desc }} </vs-td>
          <vs-td :data="data[indextr].div_desc"> {{ data[indextr].div_desc }} </vs-td>
          <vs-td :data="data[indextr].price"> {{ data[indextr].price }} </vs-td>
          <vs-td :data="data[indextr].qty"> {{ data[indextr].qty }} </vs-td>
          <vs-td :data="data[indextr].uom"> {{ data[indextr].uom }} </vs-td>
          <vs-td :data="data[indextr].unit"> {{ data[indextr].unit }} </vs-td>
          <vs-td :data="data[indextr].amount"> {{ data[indextr].amount }} </vs-td>
          <vs-td :data="data[indextr].discount"> {{ data[indextr].discount }} </vs-td>
          <vs-td :data="data[indextr].subtotal"> {{ data[indextr].subtotal }} </vs-td>
          <vs-td :data="data[indextr].tax_amount"> {{ data[indextr].tax_amount }} </vs-td>
          <vs-td :data="data[indextr].total"> {{ data[indextr].total }} </vs-td>
          <vs-td :data="data[indextr].is_free_item"> {{ data[indextr].is_free_item }} </vs-td>
          <vs-td :data="data[indextr].status"> {{ data[indextr].status }} </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startInvoiceDate: {
      type: Date,
    },
    endInvoiceDate: {
      type: Date,
    },
    startDueDate: {
      type: Date,
    },
    endDueDate: {
      type: Date,
    },
    vendorIDs: {
      type: Array,
    },
    vendorNames: {
      type: Array,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportPromotion.tableData,
      total: (state) => state.reportPromotion.total,
      totalPerPage: (state) => state.reportPromotion.totalPerPage,
      totalPage: (state) => state.reportPromotion.totalPage,
      totalSearch: (state) => state.reportPromotion.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({
      getTableData: 'reportPromotion/getPromotionReport',
      generateAPReport: 'reportPromotion/generatePromotionReport'
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'username',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search == '' ? undefined : this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_doc_posting_date:
          this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
        end_doc_posting_date:
          this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
        start_invoice_date:
          this.startInvoiceDate == null
            ? undefined
            : moment(this.startInvoiceDate).format('YYYY-MM-DD'),
        end_invoice_date:
          this.endInvoiceDate == null
            ? undefined
            : moment(this.endInvoiceDate).format('YYYY-MM-DD'),
        start_due_date:
          this.startDueDate == null
            ? undefined
            : moment(this.startDueDate).format('YYYY-MM-DD'),
        end_due_date:
          this.endDueDate == null
            ? undefined
            : moment(this.endDueDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        vendor_code:
          this.vendorIDs.length == 0 ? undefined : this.vendorIDs.join(','),
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      this.generateAPReport({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        start_doc_posting_date:
          this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
        end_doc_posting_date:
          this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
        start_invoice_date:
          this.startInvoiceDate == null
            ? undefined
            : moment(this.startInvoiceDate).format('YYYY-MM-DD'),
        end_invoice_date:
          this.endInvoiceDate == null
            ? undefined
            : moment(this.endInvoiceDate).format('YYYY-MM-DD'),
        start_due_date:
          this.startDueDate == null
            ? undefined
            : moment(this.startDueDate).format('YYYY-MM-DD'),
        end_due_date:
          this.endDueDate == null
            ? undefined
            : moment(this.endDueDate).format('YYYY-MM-DD'),
        territory:
          this.territoryIDs.length == 0
            ? undefined
            : this.territoryIDs.join(','),
        vendor_code:
          this.vendorIDs.length == 0 ? undefined : this.vendorIDs.join(','),
      }).then((resp) => {
        this.$vs.notify({
          color: 'success',
          title: 'Processing',
          text: resp.message,
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-x-circle',
        });
        this.$vs.loading.close();
      })
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
